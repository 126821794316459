/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  manageClasses, slugLoftyEqualTo,
} from '../util/componentsUtil';
import DynamicComponent from './DynamicComponent';
import {generateClassStatus, generateDaysMonth, monthLabels} from '../util/CalendarUtil';
// import {searchComponentsBySlug} from '../util/searchPage';

function ItemCalendar({
  // props del dynamic component
  component, dataEndpoints, idGetEndpoint,
  objectReader, nameFiledReader, idPostEndpoint,
  idProject, addTokenApp, addUserApp, params, idPutEndpoint,
  idDeleteEndpoint, relationParam, token,
  // props del item calendar
  changeNextMonth, activeMonth, changeHour,
  changeLastMonth, now, selectDate, listHours,
  submitCalendar,
}) {
  // componente div
  if (slugLoftyEqualTo(component, 'calendarTitleName')) {
    return (
      <div className={manageClasses(component)} id={component}>
        {monthLabels[activeMonth]?.name}
      </div>
    );
  }
  if (slugLoftyEqualTo(component, 'AMButtonSection')) {
    const manageListHours = [];
    listHours?.forEach((item) => {
      const arrayTemporal = item.split(':');
      const numberHour = parseInt(arrayTemporal[0]);
      if (numberHour < 12) {
        manageListHours.push(item);
      }
    });
    return (
      <div className={manageClasses(component)} id={component.attributes?.id}>
        {
          manageListHours.map((item) => (
            <button
              type="button"
              key={item}
              className="gjs-scheduleButton"
              onClick={() => {
                changeHour(item);
              }}
            >
              {item}
            </button>
          ))
        }
      </div>
    );
  }
  if (slugLoftyEqualTo(component, 'PMButtonSection')) {
    const manageListHours = [];
    listHours?.forEach((item) => {
      const arrayTemporal = item.split(':');
      const numberHour = parseInt(arrayTemporal[0]);
      if (numberHour >= 12) {
        manageListHours.push(item);
      }
    });
    return (
      <div className={manageClasses(component)} id={component.attributes?.id}>
        {
          manageListHours.map((item) => (
            <button
              type="button"
              key={item}
              className="gjs-scheduleButton"
              onClick={() => {
                changeHour(item);
              }}
            >
              {item}
            </button>
          ))
        }
      </div>
    );
  }
  if (slugLoftyEqualTo(component, 'calendarLeftArrow')) {
    return (
      <button
        className={manageClasses(component)}
        type="button"
        id={component.attributes?.id}
        onClick={() => {
          changeLastMonth();
        }}
      />
    );
  }
  if (slugLoftyEqualTo(component, 'calendarRightArrow')) {
    return (
      <button
        id={component.attributes?.id}
        type="button"
        className={manageClasses(component)}
        onClick={() => {
          changeNextMonth();
        }}
      />
    );
  }
  if (slugLoftyEqualTo(component, 'calendarDaySection')) {
    /* const componentTd = searchComponentsBySlug(component, 'calendarDayPoint'); */
    const arrayDays = generateDaysMonth(now);
    return (
      <tbody className={manageClasses(component)} id={component.attributes?.id}>
        {
          arrayDays.map((item) => (
            <tr>
              {
                item.map((value) => (
                  <td
                    onClick={() => {
                      selectDate(value);
                    }}
                    className={generateClassStatus(value.status)}
                  >
                    {value.date.getDate()}
                  </td>
                ))
              }
            </tr>
          ))
        }
      </tbody>
    );
  }
  return (
    <DynamicComponent
      component={component}
      addTokenApp={addTokenApp}
      addUserApp={addUserApp}
      dataEndpoints={dataEndpoints}
      idDeleteEndpoint={idDeleteEndpoint}
      idGetEndpoint={idGetEndpoint}
      idPostEndpoint={idPostEndpoint}
      idProject={idProject}
      idPutEndpoint={idPutEndpoint}
      nameFiledReader={nameFiledReader}
      objectReader={objectReader}
      params={params}
      relationParam={relationParam}
      token={token}
      // props del calendario
      activeMonth={activeMonth}
      changeLastMonth={changeLastMonth}
      changeNextMonth={changeNextMonth}
      listHours={listHours}
      now={now}
      selectDate={selectDate}
      changeHour={changeHour}
      submitCalendar={submitCalendar}
    />
  );
}

export default ItemCalendar;
