export const styleManager = (gjsStyle) => {
  if (gjsStyle.length > 0) {
    let styleReturnValue = '';
    gjsStyle.forEach((index) => {
      let temp = '';
      let flagIsMediaquery = false;
      let selectorData = [];
      if (index.selectors.length > 0) {
        selectorData = index.selectors;
      } else {
        selectorData = [index.selectorsAdd];
      }
      if (index.mediaText) {
        temp += `@media ${index.mediaText} {\n`;
        flagIsMediaquery = true;
      }
      selectorData.forEach((element) => {
        if (typeof element === 'object') {
          temp += `.${element.name}`;
        }

        if ((typeof element) === 'string' && element.charAt(0) === '#') {
          temp += `${element}`;
        } else if ((typeof element) === 'string' && element.charAt(0) === '.') {
          temp += `${element}`;
        } else if ((typeof element) === 'string') {
          temp += `.${element}`;
        }
        if (index.state) {
          temp += `:${index.state} `;
        }
      });
      temp += ' {\n';
      const styleData = index.style;
      Object.entries(styleData).forEach((info) => {
        const [key, value] = info;
        // console.log('Key: ', key, 'Value: ', value, '\n');
        temp += `${key} : ${value}; \n`;
      });
      temp += '}';
      if (flagIsMediaquery) {
        temp += '}';
      }
      styleReturnValue += `${temp} \n`;
    });
    return styleReturnValue;
  }
  return '';
};

export const styleManagerMobile = (gjsStyle) => {
  const styleMobile = gjsStyle.filter((item) => (
    item.atRuleType === 'media'
    && item.mediaText === '(max-width: 480px)'
  ));
  return styleManager(styleMobile);
};

export const styleManagerTablet = (gjsStyle) => {
  const styleTablet = gjsStyle.filter((item) => (
    item.atRuleType === 'media'
    && item.mediaText === '(max-width: 992px)'
  ));
  return styleManager(styleTablet);
};

export const styleManagerDesktop = (gjsStyle) => {
  const styleTablet = gjsStyle.filter((item) => (
    item.atRuleType !== 'media'
  ));
  return styleManager(styleTablet);
};
