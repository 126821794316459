/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unstable-nested-components */
// routesjs
import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {v2SimulatorWebsite} from 'api-lofty';
// Pages
import LoadingPage from './pages/LoadingPage';
import Error404 from './pages/Error404';
import PageDynamic from './pages/PageDynamic';

function initSwiper() {
  // eslint-disable-next-line no-undef
  const swiper = new Swiper('.mySwiper', {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  // eslint-disable-next-line no-undef
  const swiper1 = new Swiper('.gjs-three-swiper-1', {
    slidesPerView: 4,
    centeredSlides: true,
    spaceBetween: 30,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.gjs-swiper-button-next1',
      prevEl: '.gjs-swiper-button-prev1',
    },
  });
  // eslint-disable-next-line no-undef
  const swiper2 = new Swiper('.gjs-three-swiper-2', {
    slidesPerView: 4,
    centeredSlides: true,
    spaceBetween: 30,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.gjs-swiper-button-next2',
      prevEl: '.gjs-swiper-button-prev2',
    },
  });
  // eslint-disable-next-line no-undef
  const swiper3 = new Swiper('.gjs-three-swiper-3', {
    slidesPerView: 4,
    centeredSlides: true,
    spaceBetween: 30,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.gjs-swiper-button-next3',
      prevEl: '.gjs-swiper-button-prev3',
    },
  });
  // console.log('swiper :>> ', swiper1, swiper2, swiper3);
  // console.log('swiper :>> ', swiper);
}

function App() {
  // console.log('hey');
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [endpoints, setEndpoints] = useState([]);
  const [has404, setHas404] = useState(false);
  const [info404, setInfo404] = useState([]);
  const [nameSim, setNameSim] = useState('');
  const [dataGenerate, setDataGenerate] = useState();
  const [globalState, setGlobalState] = useState([]);
  const handleData = async () => {
    try {
      const slug = window.location.hostname.split('.')[0];
      const res = await v2SimulatorWebsite({
        slug,
      });
      // console.log(res.data);
      setDataGenerate(res.data);
      if (res.project) {
        const {name} = res.data.project;
        document.title = name;
      }
      setNameSim(res.data.project);
      setPages(res.data.pages);
      res.data.pages.forEach((index) => {
        if (index.url.includes('*')) {
          setHas404(true);
          setInfo404(index);
        }
      });
      setEndpoints(res.data.endpoints);
      // console.log(res.data.variableGlobal);
      // GET GLOBAL
      // const globalStateDefine = res.data.variableGlobal.map((index) => (
      //   {...index, value: index.state}
      // ));
      const updateValues = [];
      res.data?.variableGlobal.forEach((index) => {
        const localSearcher = localStorage.getItem(`${index._id}`);
        if (localSearcher) {
          updateValues.push({...index, state: JSON.parse(localSearcher)});
        } else {
          updateValues.push(index);
        }
      });
      setGlobalState(updateValues);
      setTimeout(() => {
        initSwiper();
      }, 2000);
    } catch (error) {
      setPages([]);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    globalState.forEach((index) => {
      if (index.state) {
        localStorage.setItem(`${index._id}`, JSON.stringify(index.state));
      }
    });
  }, [globalState]);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Router>
      <Switch>
        {/* Pagina de 404 */}
        {
          pages.length > 0 ? (
            pages.map((page) => {
              if (!page.url.includes('*')) {
                return (
                  <Route
                    exact
                    key={page._id}
                    path={page.url}
                    component={(props) => (
                      <PageDynamic
                        {...props}
                        key={page._id}
                        page={page}
                        endpoints={endpoints}
                        dataGenerate={dataGenerate}
                        namePage={nameSim}
                        globalVariables={globalState}
                        setGlobalState={setGlobalState}
                      />
                    )}
                  />
                );
              }
              return null;
            })
          ) : (
            <Route exact path="*" component={Error404} />
          )
        }
        {
          has404 && (
            <Route
              exact
              path="*"
              key={info404._id}
              component={(props) => (
                <PageDynamic
                  {...props}
                  key={info404._id}
                  page={info404}
                  endpoints={endpoints}
                  dataGenerate={dataGenerate}
                  namePage={nameSim}
                />
              )}
            />
          )
        }
      </Switch>
    </Router>
  );
}

export default App;
