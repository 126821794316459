export const slugLoftyEqualTo = (component, slug) => (
  component.sluglofty === slug || component.slugLofty === slug
);

export const manageClasses = (component) => {
  let classes = '';
  if (component.classes) {
    component.classes.forEach((element) => {
      if (typeof element === 'object') {
        classes += `${element.name} `;
      } else {
        classes += `${element} `;
      }
    });
  }
  return classes;
};

// para el componente div del html
export const componentDiv = ({component}) => (
  slugLoftyEqualTo(component, 'div') || slugLoftyEqualTo(component, 'basicRow') || slugLoftyEqualTo(component, 'basicCell')
  || slugLoftyEqualTo(component, 'ReadDBContainer') // Componente de lectura
  || slugLoftyEqualTo(component, 'AddDBContainer') // Componente de Esctritura
  || slugLoftyEqualTo(component, 'UpdateBContainer')
  || slugLoftyEqualTo(component, 'DeleteDBContainer')
  || slugLoftyEqualTo(component, 'fieldDatabaseContainer') // contenedor del componente
  || slugLoftyEqualTo(component, 'userDefaultFieldDatabaseContainer')
  || slugLoftyEqualTo(component, 'fieldDatabaseText') // donde se va mostrar el campo en el reader
  || slugLoftyEqualTo(component, 'fieldBucketDatabaseContainer') || slugLoftyEqualTo(component, 'fieldDatabaseloftyFileName')
  || slugLoftyEqualTo(component, 'fieldDatabaseloftyOriginalName') || slugLoftyEqualTo(component, 'fieldDatabaseloftyUrl') || slugLoftyEqualTo(component, 'userDefaultFieldDatabaseContainer')
  || slugLoftyEqualTo(component, 'userDefaultFieldDatabaseText') || slugLoftyEqualTo(component, 'userFieldDatabaseContainer') || slugLoftyEqualTo(component, 'userFieldDatabaseText')
  || slugLoftyEqualTo(component, 'fieldDatabaseAddContainer') || slugLoftyEqualTo(component, 'fieldRelationDatabaseAddContainer')
  || slugLoftyEqualTo(component, 'fieldRichTextDatabaseAddContainer') || slugLoftyEqualTo(component, 'fieldRichTextDatabaseUpdateContainer')
  || slugLoftyEqualTo(component, 'fieldUserDatabaseAddContainer') || slugLoftyEqualTo(component, 'fieldDatabaseBucketAddContainer')
  || slugLoftyEqualTo(component, 'emailInputContainer') || slugLoftyEqualTo(component, 'formCheckboxMain') || slugLoftyEqualTo(component, 'sampleTextCheckbox')
  || slugLoftyEqualTo(component, 'formComponentContainer') || slugLoftyEqualTo(component, 'formComponentTitle') || slugLoftyEqualTo(component, 'formComponentFieldSection')
  || slugLoftyEqualTo(component, 'sampleTextInput') || slugLoftyEqualTo(component, 'formTextareaContainer')
  || slugLoftyEqualTo(component, 'hamburgerContainerAnimated') || slugLoftyEqualTo(component, 'animhamburgerComponent') || slugLoftyEqualTo(component, 'animhaburgerComponent')
  || slugLoftyEqualTo(component, 'hamburgerContainer') || slugLoftyEqualTo(component, 'hamburgerComponent') || slugLoftyEqualTo(component, 'hamburgerBar')
  || slugLoftyEqualTo(component, 'hrefButtonText') || slugLoftyEqualTo(component, 'formInputContainer') || slugLoftyEqualTo(component, 'samplePasswordInput')
  || slugLoftyEqualTo(component, 'passwordInputContainer') || slugLoftyEqualTo(component, 'swiperComponent') || slugLoftyEqualTo(component, 'swiperComponentWrapper')
  || slugLoftyEqualTo(component, 'swiperComponentSlide') || slugLoftyEqualTo(component, 'swiperButtonNext') || slugLoftyEqualTo(component, 'swiperButtonPrev')
  || slugLoftyEqualTo(component, 'swiperPagination') || slugLoftyEqualTo(component, 'standardButtonText')
  || slugLoftyEqualTo(component, 'RestorePasswordContainer') || slugLoftyEqualTo(component, 'AuthInputSection')
  || slugLoftyEqualTo(component, 'AuthPasswordSection') || slugLoftyEqualTo(component, 'userDefaultFieldRegisterUserContainer')
  || slugLoftyEqualTo(component, 'fieldUserRegisterContainer') || slugLoftyEqualTo(component, 'newPasswordDiv')
  || slugLoftyEqualTo(component, 'newPasswordDiv')
  || slugLoftyEqualTo(component, 'itemListEndpointDatabase') // item para el listado
  || slugLoftyEqualTo(component, 'fieldDatabaseUpdateContainer')
  || slugLoftyEqualTo(component, 'fieldRelationDatabaseUpdateContainer')
  || slugLoftyEqualTo(component, 'fieldUserDatabaseUpdateContainer')
  || slugLoftyEqualTo(component, 'fieldDatabaseBucketUpdateContainer')
  || slugLoftyEqualTo(component, 'formPasswordContainer')
  || slugLoftyEqualTo(component, 'fieldDatabaseTextRich')
  || slugLoftyEqualTo(component, 'calendarComponent')
  || slugLoftyEqualTo(component, 'calendarSectionLeft')
  || slugLoftyEqualTo(component, 'calendarSectionRight')
  || slugLoftyEqualTo(component, 'calendarFormTitle')
  || slugLoftyEqualTo(component, 'calendarFormComponentFields')
  || slugLoftyEqualTo(component, 'calendarFormFieldTitle')
  || slugLoftyEqualTo(component, 'calendarTitleName')
  || slugLoftyEqualTo(component, 'calendarSectionLeft')
  || slugLoftyEqualTo(component, 'calendarSectionRight')
  || slugLoftyEqualTo(component, 'scheduleLabel')
  || slugLoftyEqualTo(component, 'AMButtonSection')
  || slugLoftyEqualTo(component, 'PMButtonSection')
  || slugLoftyEqualTo(component, 'mainModalContainer')
  || slugLoftyEqualTo(component, 'modalArea')
  || slugLoftyEqualTo(component, 'modalButtonText')
  || slugLoftyEqualTo(component, 'modalInitPage')
  || slugLoftyEqualTo(component, 'modalInitContainer')
  || slugLoftyEqualTo(component, 'updatePasswordDiv')
  || slugLoftyEqualTo(component, 'verifyUserEmail')
  || slugLoftyEqualTo(component, 'threeSwiperComponent')
  || slugLoftyEqualTo(component, 'ThreeSwiperComponentSub1')
  || slugLoftyEqualTo(component, 'ThreeSwiperComponentWrapper')
  || slugLoftyEqualTo(component, 'swiperButtonNext')
  || slugLoftyEqualTo(component, 'swiperButtonPrev')
  || slugLoftyEqualTo(component, 'ThreeSwiperComponentSub2')
  || slugLoftyEqualTo(component, 'ThreeSwiperComponentSub3')
  || slugLoftyEqualTo(component, 'modalComponent')
  || slugLoftyEqualTo(component, 'modalDialog')
  || slugLoftyEqualTo(component, 'modalHeader')
  || slugLoftyEqualTo(component, 'modalBody')
  || slugLoftyEqualTo(component, 'modalFooter')
  || slugLoftyEqualTo(component, 'variableComponent')
  || slugLoftyEqualTo(component, 'booleanDynamicComponent')
  || slugLoftyEqualTo(component, 'booleanTrueDivDynamic')
  || slugLoftyEqualTo(component, 'booleanFalseDivDynamic')
  || slugLoftyEqualTo(component, 'variableArrayComponent')
  || slugLoftyEqualTo(component, 'oneDataVariable')
  || slugLoftyEqualTo(component, 'listArrayVariable')
  || slugLoftyEqualTo(component, 'itemListArrayVariable')
  || slugLoftyEqualTo(component, 'reactCarouselComponent')
  || slugLoftyEqualTo(component, 'listCartData')
  || slugLoftyEqualTo(component, 'itemCartData')
  || slugLoftyEqualTo(component, 'quantityItemCartData')
);

// div user auth login & register
export const componentUserAuth = ({component}) => (
  slugLoftyEqualTo(component, 'AuthenticationUserContainer') // formulario de add Database
  || slugLoftyEqualTo(component, 'RegisterUserContainer')
  || slugLoftyEqualTo(component, 'UpdateUserPasswordContainer')
);

// para el componente form de html
export const componentForm = ({component}) => (
  slugLoftyEqualTo(component, 'formAddDatabase') // formulario de add Database
  || slugLoftyEqualTo(component, 'formBasicComponent')
  || slugLoftyEqualTo(component, 'AuthSection') // formulario para la autenticación
  || slugLoftyEqualTo(component, 'RegisterSection') // formulario para registrar usuario
  || slugLoftyEqualTo(component, 'ResetPasswordSection')
  || slugLoftyEqualTo(component, 'formUpdateDatabase')
  || slugLoftyEqualTo(component, 'mainFormForgotPassword')
  || slugLoftyEqualTo(component, 'calendarForm')
  || slugLoftyEqualTo(component, 'UpdatePasswordSection')
  || slugLoftyEqualTo(component, 'formUpdateUserInfo')
);

// para el componente label del html
export const componentLabel = ({component}) => (
  slugLoftyEqualTo(component, 'formLabelStringDatabase') || slugLoftyEqualTo(component, 'formLabelDateDatabase')
  || slugLoftyEqualTo(component, 'formLabelNumberDatabase') || slugLoftyEqualTo(component, 'formLabelBooleanDatabase')
  || slugLoftyEqualTo(component, 'formLabelUserDatabase') || slugLoftyEqualTo(component, 'formLabelBucketDatabase')
  || slugLoftyEqualTo(component, 'sampleEmailInput') || slugLoftyEqualTo(component, 'formCheckboxContainer')
  || slugLoftyEqualTo(component, 'hamburgerAnimMenu') || slugLoftyEqualTo(component, 'formLabelCheckbox')
  || slugLoftyEqualTo(component, 'formLabelSelectRelationDatabase') || slugLoftyEqualTo(component, 'formLabelSelectDatabase')
  || slugLoftyEqualTo(component, 'hamburgerAnimMenuClose') || slugLoftyEqualTo(component, 'inputUserEmailAuthLabel')
  || slugLoftyEqualTo(component, 'inputPasswordAuthLabel') || slugLoftyEqualTo(component, 'formLabelDefaultFieldRegisterUser')
  || slugLoftyEqualTo(component, 'formLabelDefaultBooleanRegisterUser') || slugLoftyEqualTo(component, 'formLabelDefaultFieldRegisterUserCheckbox')
  || slugLoftyEqualTo(component, 'formLabelStringRegisterUser') || slugLoftyEqualTo(component, 'formLabelRegisterUser')
  || slugLoftyEqualTo(component, 'formLabelNumberRegisterUser') || slugLoftyEqualTo(component, 'formLabelBooleanRegisterUser')
  || slugLoftyEqualTo(component, 'formLabelRegisterUserCheckbox') || slugLoftyEqualTo(component, 'newPasswordLabel')
  || slugLoftyEqualTo(component, 'confirmPasswordLabel') || slugLoftyEqualTo(component, 'formUpdateLabelStringDatabase')
  || slugLoftyEqualTo(component, 'formUpdateLabelDateDatabase') || slugLoftyEqualTo(component, 'formUpdateLabelNumberDatabase')
  || slugLoftyEqualTo(component, 'formUpdateLabelBooleanDatabase') || slugLoftyEqualTo(component, 'formLabelUpdateCheckbox')
  || slugLoftyEqualTo(component, 'formUpdateLabelSelectRelationDatabase')
  || slugLoftyEqualTo(component, 'formUpdateLabelUserDatabase')
  || slugLoftyEqualTo(component, 'formUpdateLabelBucketDatabase')
  || slugLoftyEqualTo(component, 'labelForInputEmailForgotPassword')
  || slugLoftyEqualTo(component, 'formLabelRichTextDatabaseS')
  || slugLoftyEqualTo(component, 'scheduleLabel')
  || slugLoftyEqualTo(component, 'currentPasswordLabel')
  || slugLoftyEqualTo(component, 'newPasswordUpdateLabel')
  || slugLoftyEqualTo(component, 'confirmPasswordUpdateLabel')
);

// para el componente input del html
export const componentInput = ({component}) => (
  slugLoftyEqualTo(component, 'formInputDatabase') // input de la base de datos
  || slugLoftyEqualTo(component, 'formDateInputDatabase')
  || slugLoftyEqualTo(component, 'formNumberInputDatabase') || slugLoftyEqualTo(component, 'formBooleanInputDatabase')
  || slugLoftyEqualTo(component, 'formBucketFileDatabase') || slugLoftyEqualTo(component, 'emailInput')
  || slugLoftyEqualTo(component, 'formCheckbox') || slugLoftyEqualTo(component, 'formInput')
  || slugLoftyEqualTo(component, 'hamburgerAnimToggle') || slugLoftyEqualTo(component, 'InputUserAuth')
  || slugLoftyEqualTo(component, 'passwordInput') || slugLoftyEqualTo(component, 'PasswordUserAuth')
  || slugLoftyEqualTo(component, 'formInputRegisterUser') || slugLoftyEqualTo(component, 'formBooleanInputRegisterUser')
  || slugLoftyEqualTo(component, 'formInputDefaultFieldRegisterUser') || slugLoftyEqualTo(component, 'formPasswordDefaultFieldRegisterUser')
  || slugLoftyEqualTo(component, 'formDateInputDefaultFieldRegisterUser') || slugLoftyEqualTo(component, 'formDateInputDefaultFieldRegisterUser')
  || slugLoftyEqualTo(component, 'formDateInputRegisterUser') || slugLoftyEqualTo(component, 'formNumberInputRegisterUser')
  || slugLoftyEqualTo(component, 'formBooleanInputRegisterUser') || slugLoftyEqualTo(component, 'NewPasswordUser')
  || slugLoftyEqualTo(component, 'ConfirmPasswordUser')
  || slugLoftyEqualTo(component, 'formInputUpdateDatabase')
  || slugLoftyEqualTo(component, 'formDateInputUpdateDatabase')
  || slugLoftyEqualTo(component, 'formNumberInputUpdateDatabase')
  || slugLoftyEqualTo(component, 'formBooleanInputUpdateDatabase')
  || slugLoftyEqualTo(component, 'formBucketFileDatabaseUpdate')
  || slugLoftyEqualTo(component, 'formInputEmailForgotPass')
  || slugLoftyEqualTo(component, 'calendarFormInput')
  || slugLoftyEqualTo(component, 'newUpdatePassword')
  || slugLoftyEqualTo(component, 'confirmUpdatePassword')
  || slugLoftyEqualTo(component, 'formInputDefaultFieldUpdateUser')
);

// para el component table de html
export const componentTable = ({component}) => (
  slugLoftyEqualTo(component, 'calendarTable')
);

// para el componente thead de html
export const componentThead = ({component}) => (
  slugLoftyEqualTo(component, 'calendarHeadSection')
);

// para el componente tbody html
export const componentTBody = ({component}) => (
  slugLoftyEqualTo(component, 'calendarDaySection')
);

// para el componente th html
export const componentTh = ({component}) => (
  slugLoftyEqualTo(component, 'calendarSunday') || slugLoftyEqualTo(component, 'calendarMonday')
  || slugLoftyEqualTo(component, 'calendarTuesday') || slugLoftyEqualTo(component, 'calendarWednesday')
  || slugLoftyEqualTo(component, 'calendarThursday') || slugLoftyEqualTo(component, 'calendarFriday')
  || slugLoftyEqualTo(component, 'calendarSaturday')
  || slugLoftyEqualTo(component, 'calendarThTitleComponent')
);

// para el componente tr de html
export const componentTr = ({component}) => (
  slugLoftyEqualTo(component, 'calendarWeekDays') || slugLoftyEqualTo(component, 'calendarTitleRow')
  || slugLoftyEqualTo(component, 'calendarDaysArea')
);

// para el componente td de html
export const componentTd = ({component}) => (
  slugLoftyEqualTo(component, 'calendarDayPoint')
);

// para el componente button de html
export const componentButton = ({component}) => (
  slugLoftyEqualTo(component, 'formAddDatabaseButton') || slugLoftyEqualTo(component, 'loginUserButton')
  || slugLoftyEqualTo(component, 'formButton') || slugLoftyEqualTo(component, 'formLabelRegisterUserCheckbox')
  || slugLoftyEqualTo(component, 'standardButton') || slugLoftyEqualTo(component, 'deletionButton')
  || slugLoftyEqualTo(component, 'eliminateRead') || slugLoftyEqualTo(component, 'formUpdateDatabaseButton')
  || slugLoftyEqualTo(component, 'formButtonForgotPassword') || slugLoftyEqualTo(component, 'scheduleHourButton')
  || slugLoftyEqualTo(component, 'calendarFormButton')
  || slugLoftyEqualTo(component, 'calendarLeftArrow')
  || slugLoftyEqualTo(component, 'calendarRightArrow')
  || slugLoftyEqualTo(component, 'registerUserButton')
  || slugLoftyEqualTo(component, 'modalButton')
  || slugLoftyEqualTo(component, 'logoutUserButtonComponent')
  || slugLoftyEqualTo(component, 'resetUserButton')
  || slugLoftyEqualTo(component, 'updatePasswordButton')
  || slugLoftyEqualTo(component, 'updateUserInfoButton')
);

// para el component select de html
export const componentSelect = ({component}) => (
  slugLoftyEqualTo(component, 'formSelectRelation') || slugLoftyEqualTo(component, 'formSelectUser')
  || slugLoftyEqualTo(component, 'formSelectRelationUpdate') || slugLoftyEqualTo(component, 'formSelectUserUpdate')
);

// component ol
export const componentOlList = ({component}) => (
  slugLoftyEqualTo(component, 'listComponent')
);

// component li for HTML lists
export const componentListItem = ({component}) => (
  slugLoftyEqualTo(component, 'listItem')
);

// Component dynamic image DB
export const componentDynImage = ({component}) => (
  slugLoftyEqualTo(component, 'dynImage')
);

// Component animated Scroll Container
export const componentAnimatedScrollContainer = ({component}) => (
  slugLoftyEqualTo(component, 'animatedScrollContainer')
);

// Component dynamic video
export const componentDynamicVideo = ({component}) => (
  slugLoftyEqualTo(component, 'dynVideo')
);
