export const searchComponentsBySlug = (gjsComponents, slug) => {
  if (gjsComponents.length > 0) {
    let arrayComponents = [];
    gjsComponents.forEach((element) => {
      if (element.sluglofty === slug || element.slugLofty === slug) {
        arrayComponents.push(element);
        if (element.components?.length > 0) {
          const arraySubComponent = searchComponentsBySlug(element.components, slug);
          const newArray = arrayComponents.concat(arraySubComponent);
          arrayComponents = newArray;
        }
      } else if (element.components?.length > 0) {
        const arraySubComponent = searchComponentsBySlug(element.components, slug);
        const newArray = arrayComponents.concat(arraySubComponent);
        arrayComponents = newArray;
      }
    });
    return arrayComponents;
  }
  return [];
};
