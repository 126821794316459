import React, {useState} from 'react';
import {createReactEditorJS} from 'react-editor-js';
import {addAssetsProject} from 'api-lofty';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
/* import LinkTool from '@editorjs/link'; */
import Table from '@editorjs/table';
import ImageTool from '@editorjs/image';

const ReactEditorJS = createReactEditorJS();

function EditorJs({
  defaultValue, id, className,
  name, idProject, token,
}) {
  const [value, setValue] = useState('');
  const EDITOR_JS_TOOLS = {
    paragraph: Paragraph,
    table: Table,
    list: List,
    image: {
      class: ImageTool,
      config: {
        uploader: {
          uploadByFile(file) {
            return addAssetsProject({
              idProject,
              token,
              files: [file],
            }).then((res) => ({
              success: 1,
              file: {
                url: res.data[0]?.src,
              },
            }));
          },
        },
        additionalRequestHeaders: {
          Authorization: token,
        },
      },
    },
  };
  return (
    <div
      id={id}
      className={className}
    >
      <ReactEditorJS
        style={{
          width: '100%',
        }}
        defaultValue={{
          time: new Date(),
          blocks: defaultValue,
        }}
        tools={EDITOR_JS_TOOLS}
        onChange={async (api) => {
          const output = await api.saver.save();
          setValue(JSON.stringify(output.blocks));
        }}
      />
      <input value={value} type="text" name={name} style={{display: 'none'}} />
    </div>
  );
}

export default EditorJs;
