/* eslint-disable no-alert */
import React, {useEffect, useState} from 'react';
import {createMeeting, listSchedule} from 'api-lofty';
import {manageClasses} from '../util/componentsUtil';
import ItemCalendar from './ItemCalendarDynamic';
import {monthLabels} from '../util/CalendarUtil';

function CalendarDynamic({
  component, dataEndpoints, idGetEndpoint,
  objectReader, nameFiledReader, idPostEndpoint,
  idProject, addTokenApp, addUserApp, params, idPutEndpoint,
  idDeleteEndpoint, relationParam, token,
}) {
  const [now, setNow] = useState(new Date());
  const [activeMonth, setActiveMonth] = useState(now.getMonth());
  const [selectedDate, setSelectedDate] = useState();
  const [listHours, setListHours] = useState([]);
  const [selectedHour, setSelectedHour] = useState('');
  useEffect(() => {
    // ordenando la visualización del componente
    setTimeout(() => {
      const calendar = document.getElementsByClassName('calendarTable');
      if (calendar.length > 0) {
        calendar[0].style.display = 'block';
      }
      const showSchedule = document.getElementsByClassName('scheduleBasicStyle');
      if (showSchedule.length > 0) {
        showSchedule[0].style.display = 'none';
      }
      const formSchedule = document.getElementsByClassName('FormCalendarStyle');
      if (formSchedule.length > 0) {
        formSchedule[0].style.display = 'none';
      }
    }, 1500);
  }, []);
  return (
    <div id={component.attributes?.id} className={manageClasses(component)}>
      {
        component?.components?.map((item) => (
          <ItemCalendar
            component={item}
            activeMonth={activeMonth}
            addTokenApp={addTokenApp}
            addUserApp={addUserApp}
            dataEndpoints={dataEndpoints}
            idDeleteEndpoint={idDeleteEndpoint}
            idGetEndpoint={idGetEndpoint}
            idPostEndpoint={idPostEndpoint}
            idProject={idProject}
            idPutEndpoint={idPutEndpoint}
            nameFiledReader={nameFiledReader}
            objectReader={objectReader}
            params={params}
            relationParam={relationParam}
            token={token}
            now={now}
            listHours={listHours}
            changeNextMonth={() => {
              if (activeMonth < monthLabels.length - 1) {
                setActiveMonth(activeMonth + 1);
                setNow(new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()));
              } else {
                setActiveMonth(0);
                setNow(new Date(now.getFullYear() + 1, 0, now.getDate()));
              }
            }}
            changeLastMonth={() => {
              if (activeMonth > 0) {
                setActiveMonth(activeMonth - 1);
                setNow(new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()));
              } else {
                setActiveMonth(11);
                setNow(new Date(now.getFullYear() - 1, 11, now.getDate()));
              }
            }}
            selectDate={async (value) => {
              setSelectedDate(value.date);
              const calendar = document.getElementsByClassName('calendarTable');
              if (calendar.length > 0) {
                calendar[0].style.display = 'none';
              }
              const showSchedule = document.getElementsByClassName('scheduleBasicStyle');
              if (showSchedule.length > 0) {
                showSchedule[0].style.display = 'block';
              }
              const formSchedule = document.getElementsByClassName('FormCalendarStyle');
              if (formSchedule.length > 0) {
                formSchedule[0].style.display = 'none';
              }
              const res = await listSchedule({
                idPluginCalendar: component.calendarPlugin,
                selectedDate: value.date,
              });
              setListHours(res.data);
            }}
            changeHour={(hour) => {
              setSelectedHour(hour);
              const calendar = document.getElementsByClassName('calendarTable');
              if (calendar.length > 0) {
                calendar[0].style.display = 'none';
              }
              const showSchedule = document.getElementsByClassName('scheduleBasicStyle');
              if (showSchedule.length > 0) {
                showSchedule[0].style.display = 'none';
              }
              const formSchedule = document.getElementsByClassName('FormCalendarStyle');
              if (formSchedule.length > 0) {
                formSchedule[0].style.display = 'block';
              }
            }}
            submitCalendar={async (e) => {
              const nameCalendarValue = e.target.nameInputCalendar?.value;
              const emailCalendarValue = e.target.emailInputCalendar?.value;
              const messageCalendarValue = e.target.messageInputCalendar?.value;
              const phoneCalendarValue = e.target.phoneInputCalendar?.value;
              const dirCalendarValue = e.target.dirInputCalendar?.value;
              try {
                await createMeeting({
                  idPluginCalendar: component.calendarPlugin,
                  loftyAddress: dirCalendarValue,
                  loftyDate: selectedDate,
                  loftyEmail: emailCalendarValue,
                  loftyHours: selectedHour,
                  loftyName: nameCalendarValue,
                  loftyReason: messageCalendarValue,
                  loftyTelephone: phoneCalendarValue,
                });
                alert('Se agrego el dato de manera exitosa');
              } catch (error) {
                if (error.info) {
                  alert(error.info);
                } else {
                  alert('Error al procesar la información');
                }
              }
            }}
          />
        ))
      }
    </div>
  );
}

export default CalendarDynamic;
