/* eslint-disable no-plusplus */
export const monthLabels = [
  {
    name: 'Enero',
  },
  {
    name: 'Febrero',
  },
  {
    name: 'Marzo',
  },
  {
    name: 'Abril',
  },
  {
    name: 'Mayo',
  },
  {
    name: 'Junio',
  },
  {
    name: 'Julio',
  },
  {
    name: 'Agosto',
  },
  {
    name: 'Septiembre',
  },
  {
    name: 'Octubre',
  },
  {
    name: 'Noviembre',
  },
  {
    name: 'Diciembre',
  },
];

const setStatus = (date, initialDate) => {
  if (initialDate.getFullYear() < date.getFullYear()) {
    return 'last';
  }
  if (
    initialDate.getFullYear() === date.getFullYear() && date.getMonth() < initialDate.getMonth()
  ) {
    return 'last';
  }
  if (initialDate.getFullYear() > date.getFullYear()) {
    return 'next';
  }
  if (
    initialDate.getFullYear() === date.getFullYear() && date.getMonth() > initialDate.getMonth()
  ) {
    return 'next';
  }
  return 'local';
};

export const generateClassStatus = (status) => {
  if (status === 'last') {
    return 'gjs-jsCalendar-previous';
  }
  if (status === 'next') {
    return 'gjs-jsCalendar-next';
  }
  return '';
};

export const generateDaysMonth = (date) => {
  const arrayDays = [];
  const lastDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  lastDate.setMonth(date.getMonth() - 1);
  nextDate.setMonth(date.getMonth() + 1);
  const initialDate = new Date(date.getFullYear(), date.getMonth(), 1);
  for (let row = 0; row < 6; row++) {
    const arrayRows = [];
    for (let colum = 0; colum < 7; colum++) {
      if (initialDate.getDay() === colum) {
        const status = setStatus(date, initialDate);
        arrayRows.push({
          date: new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate()),
          status,
        });
        initialDate.setDate(initialDate.getDate() + 1);
      } else {
        initialDate.setDate(initialDate.getDate() - initialDate.getDay());
        const status = setStatus(date, initialDate);
        arrayRows.push({
          date: new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate()),
          status,
        });
        initialDate.setDate(initialDate.getDate() + 1);
      }
    }
    arrayDays.push(arrayRows);
  }
  return arrayDays;
};
