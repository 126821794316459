/* eslint-disable function-paren-newline */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-properties */
/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable no-else-return */

/**
 *
 * Globales por todo el proceso del project
 * Local Por Pagina
 */

const logicSimulatorFrontendCustom = (
  block,
  globalVariables,
  localstate,
  setGlobalState,
  setLocalState,
  tempVariables,
) => {
  // bloque principal
  // console.log('lets start', globalVariables, localstate);
  if (block.type === 'main_block') {
    if (block.inputs?.main_block?.block) {
      const response = logicSimulatorFrontendCustom(
        block.inputs.main_block.block,
        globalVariables,
        localstate,
        setGlobalState,
        setLocalState,
        tempVariables,
      );
      return response;
    }
  }

  if (block.type === 'variables_set') {
    let valueVariable;
    if (block.inputs?.VALUE?.block) {
      valueVariable = logicSimulatorFrontendCustom(
        block.inputs.VALUE.block,
        globalVariables,
        localstate,
        setGlobalState,
        setLocalState,
        tempVariables,
      );
    }
    if (block.fields?.VAR?.id) {
      const findVariable = tempVariables.find(
        (variable) => (variable.id === block.fields?.VAR?.id),
      );
      if (!findVariable) {
        tempVariables.push({id: block.fields?.VAR?.id, value: undefined});
      }
      tempVariables.forEach((variable) => {
        if (variable.id === block.fields?.VAR?.id) {
          variable.value = valueVariable;
        }
      });
    }
  }

  if (block.type === 'math_change') {
    let valueVariable;
    if (block.inputs?.DELTA?.shadow) {
      valueVariable = logicSimulatorFrontendCustom(
        block.inputs?.DELTA?.shadow,
        globalVariables,
        localstate,
        setGlobalState,
        setLocalState,
        tempVariables,
      );
    }
    if (block.fields?.VAR?.id) {
      const findVariable = tempVariables.find(
        (variable) => (variable.id === block.fields?.VAR?.id),
      );
      if (!findVariable) {
        tempVariables.push({id: block.fields?.VAR?.id, value: ''});
      }
      tempVariables.forEach((variable) => {
        if (variable.id === block.fields?.VAR?.id) {
          variable.value += valueVariable;
        }
      });
    }
  }

  if (block.type === 'variables_get') {
    if (block.fields?.VAR?.id) {
      const variable = tempVariables.find((variable) => variable.id === block.fields?.VAR?.id);
      if (variable?.value) {
        return variable.value;
      }
    }
  }

  // bloque para setear variable
  if (block.type === 'set_global_variable_page') {
    let valueVariable;
    if (block.inputs?.definedTo?.block) {
      valueVariable = block.fields?.variableNameGlobal;
      let setCurrentValue;
      if (block.inputs?.definedTo?.block?.fields) {
        setCurrentValue = logicSimulatorFrontendCustom(
          block.inputs?.definedTo?.block,
          globalVariables,
          localstate,
          setGlobalState,
          setLocalState,
          tempVariables,
        );
      }
      const updateToData = [...globalVariables];
      const captureObject = updateToData.find((obj) => (obj.name === valueVariable));
      if (captureObject) {
        captureObject.state = setCurrentValue;
        setGlobalState(updateToData);
      }
    }
    // if (block.fields?.VAR?.id) {
    //   variables.forEach((variable) => {
    //     if (variable.id === block.fields?.VAR?.id) {
    //       variable.value = valueVariable;
    //     }
    //   });
    // }
  }

  // assign value
  if (block.type === 'assign_variable') {
    let variableInstanceData;
    if (block.inputs?.variableInstance?.block) {
      // console.log(block.inputs.definedTo.block?.fields);
      variableInstanceData = logicSimulatorFrontendCustom(
        block.inputs?.variableInstance?.block,
        globalVariables,
        localstate,
        setGlobalState,
        setLocalState,
        tempVariables,
      );
      // console.log(varSeeker);
    }
    let varSeeker;
    if (block.inputs?.definedTo?.block) {
      // console.log(block.inputs.definedTo.block?.fields);
      varSeeker = block.inputs.definedTo.block?.fields;
      // console.log(varSeeker);
    }
    if (varSeeker?.variableNameGlobal) {
      const updateToData = [...globalVariables];
      const captureObject = updateToData.find((obj) => (
        obj.name === varSeeker?.variableNameGlobal
      ));
      if (captureObject) {
        captureObject.state = variableInstanceData;
        setGlobalState(updateToData);
      }
    } else {
      const updateToDataLocal = [...localstate];
      const captureObjectLocal = updateToDataLocal.find((obj) => (
        obj.slug === varSeeker?.variableNameLocal
      ));
      if (captureObjectLocal) {
        captureObjectLocal.state = variableInstanceData;
        setLocalState(updateToDataLocal);
      }
    }
  }

  // variable redirect
  if (block.type === 'navigator_redirect') {
    if (block.inputs?.urlInstance?.block) {
      if (
        block.inputs.urlInstance.block?.type === 'local_dynamic_variable'
        || block.inputs.urlInstance.block?.type === 'global_dynamic_variable'
        || block.inputs.urlInstance.block?.type === 'variables_get'
      ) {
        const captureInfoData = logicSimulatorFrontendCustom(
          block.inputs.urlInstance.block,
          globalVariables,
          localstate,
          setGlobalState,
          setLocalState,
          tempVariables,
        );
        if (captureInfoData) {
          window.location.href = captureInfoData;
        }
      } else {
        const varRedirect = block.inputs.urlInstance.block.fields.valueInput;
        window.location.href = varRedirect;
      }
    }
  }

  if (block.type === 'conditional_if_block') {
    if (block?.inputs?.condition?.block) {
      const getResultFromCondition = logicSimulatorFrontendCustom(
        block?.inputs?.condition?.block,
        globalVariables,
        localstate,
        setGlobalState,
        setLocalState,
        tempVariables,
      );
      if (block?.inputs?.then?.block && getResultFromCondition) {
        logicSimulatorFrontendCustom(
          block?.inputs?.then?.block,
          globalVariables,
          localstate,
          setGlobalState,
          setLocalState,
          tempVariables,
        );
      }
    }
  }

  if (block.type === 'if_condition') {
    let leftValue;
    if (block.inputs?.comparison_left_condition?.block) {
      leftValue = logicSimulatorFrontendCustom(
        block.inputs.comparison_left_condition.block,
        globalVariables,
        localstate,
        setGlobalState,
        setLocalState,
        tempVariables,
      );
    }
    let rightValue;
    if (block.inputs?.comparison_right_condition?.block) {
      rightValue = logicSimulatorFrontendCustom(
        block.inputs.comparison_right_condition.block,
        globalVariables,
        localstate,
        setGlobalState,
        setLocalState,
        tempVariables,
      );
    }
    if (block.fields?.comparison_type_select) {
      const conditionalState = block.fields.comparison_type_select;
      if (conditionalState === 'equals') {
        return leftValue === rightValue;
      } else if (conditionalState === 'more than') {
        return leftValue > rightValue;
      } else if (conditionalState === 'less than') {
        return leftValue < rightValue;
      } else if (conditionalState === 'more or equal than') {
        return leftValue >= rightValue;
      } else if (conditionalState === 'less or equal than') {
        return leftValue <= rightValue;
      } else if (conditionalState === 'different') {
        return leftValue !== rightValue;
      } else if (conditionalState === 'regex') {
        const regex = new RegExp(leftValue, 'i');
        return regex.test(rightValue);
      }
    }
  }

  // bloque de matematicas
  if (block.type === 'operation') {
    let leftValue;
    if (block.inputs?.left_value?.block) {
      leftValue = logicSimulatorFrontendCustom(
        block.inputs.left_value.block,
        globalVariables,
        localstate,
        setGlobalState,
        setLocalState,
        tempVariables,
      );
    }
    let rightValue;
    if (block.inputs?.arithmeticoptions?.block) {
      rightValue = logicSimulatorFrontendCustom(
        block.inputs.arithmeticoptions.block,
        globalVariables,
        localstate,
        setGlobalState,
        setLocalState,
        tempVariables,
      );
    }
    if (block.fields?.arithmetic) {
      if (block.fields.arithmetic === 'sum') {
        return leftValue + rightValue;
      } else if (block.fields.arithmetic === 'difference') {
        return leftValue - rightValue;
      } else if (block.fields.arithmetic === 'product') {
        return leftValue * rightValue;
      } else if (block.fields.arithmetic === 'division') {
        return leftValue / rightValue;
      } else if (block.fields.arithmetic === 'power') {
        return Math.pow(leftValue, rightValue);
      } else if (block.fields.arithmetic === 'square_root') {
        return Math.pow(rightValue, 1 / leftValue);
      }
    }
  }

  // Bloque que contiene numero
  if (block.type === 'number_input_block') {
    return block.fields?.valueInputNumber;
  }
  // Bloque que contiene boolean
  if (block.type === 'boolean_input_block') {
    // console.log(block);
    if (block.fields?.booleanTypeSelect === 'true') {
      return true;
    }
    if (block.fields?.booleanTypeSelect === 'false') {
      return false;
    }
  }
  // Bloque que contiene un string
  if (block.type === 'string_input_block') {
    return block.fields?.valueInput;
  }

  // Local Variable
  if (block.type === 'local_dynamic_variable') {
    const captureObject = localstate.find((obj) => (obj.slug === block.fields?.variableNameLocal));
    return captureObject?.state;
  }

  // Global Variable
  if (block.type === 'global_dynamic_variable') {
    const captureObject = globalVariables.find((obj) => (
      obj.name === block.fields?.variableNameGlobal));
    return captureObject?.state;
  }

  // Bloque para el variable get
  // if (block.type === 'variables_get') {
  //   if (block.fields?.VAR?.id) {
  //     const variable = variables.find((variable) => variable.id === block.fields?.VAR?.id);
  //     return variable;
  //   }
  // }

  // Bloque para la respuesta
  // if (block.type === 'query_response_block') {
  //   let statusResponse: any;
  //   if (block.fields?.type_status) {
  //     statusResponse = block.fields.type_status;
  //   }
  //   if (block.inputs?.responsedata?.block) {
  //     const value = await logicSimulatorCustomEndpoint(
  //       block.inputs.responsedata.block, req, variables, params,
  //       project
  //     );
  //     return {
  //       status: statusResponse,
  //       info: 'Se realizo de manera exitosa',
  //       data: value,
  //     }
  //   }
  // }

  // Bloque de añadir datos
  // if (block.type === 'math_change') {
  //   let valueVariable: any;
  //   if (block.inputs?.DELTA?.shadow) {
  //     valueVariable = await logicSimulatorCustomEndpoint(
  //       block.inputs?.DELTA?.shadow, req, variables, params,
  //       project
  //     );
  //   }
  //   if (block.fields?.VAR?.id) {
  //     variables.forEach((variable) => {
  //       if (variable.id === block.fields?.VAR?.id) {
  //         variable.value = valueVariable;
  //       }
  //     });
  //   }
  // }

  // Bloque Math Number que se encuentra dentro de añadir datos
  if (block.type === 'math_number') {
    return block.fields?.NUM;
  }

  // si posee un next que continue la recursividad
  if (block.next?.block) {
    return logicSimulatorFrontendCustom(
      block.next.block,
      globalVariables,
      localstate,
      setGlobalState,
      setLocalState,
      tempVariables,
    );
  }
};

export default logicSimulatorFrontendCustom;
