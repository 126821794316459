/* eslint-disable no-loop-func */
/* eslint-disable max-len */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  listSimulatorDatabase, oneSimulatorDatabase, getLoggedSimulatorUser,
  oneSimulatorCustomDatabase, listSimulatorCustomDatabase,
} from 'api-lofty';
import AOS from 'aos';
import DynamicComponent from '../components/DynamicComponent';
import {addToken, addUser, deletetoken} from '../actions/auth.actions';
import {searchComponentsBySlug} from '../util/searchPage';
import {styleManagerDesktop, styleManagerMobile, styleManagerTablet} from '../util/styleManager';
// import ModalSimulator from '../components/ModalSimulator';

function PageDynamic({
  page, match, endpoints, addUserApp, deletetokenApp, addTokenApp,
  dataGenerate, token, user, globalVariables, setGlobalState,
  // namePage,
}) {
  const [dataEndpoints, setDataEndpoints] = useState([]);
  const [localPage, setLocalPage] = useState(page);
  const [notLogged, setNotLogged] = useState(false);
  const [userInfo, setUserInfo] = useState(user);
  const [localState, setLocalState] = useState([]);
  const [variablePerEndpoint, setVariablePerEndpoint] = useState([]);
  // const [messageInfo, setMessageInfo] = useState('');
  // const [firstTimeSim, setFirstTimeSim] = useState(false);
  // function handlerUserUpdate(currentInput, valueInput) {
  //   // console.log('called');
  //   setUserInfo({...userInfo, [currentInput]: valueInput});
  // }

  function findVariableExists(id, tempLocal) {
    const localInfoState = tempLocal || localState;
    const localSeeker = localInfoState.find((index) => (index._id === id));
    const globalSeeker = globalVariables.find((index) => (index._id === id));
    if (localSeeker) {
      return localSeeker;
    }
    if (globalSeeker) {
      return globalSeeker;
    }
    return null;
  }

  // const variableDataConverter = (variable, type) => {
  //   if (type === 'string') {
  //     return variable;
  //   }
  //   if (type === 'number') {
  //     return +variable;
  //   }
  //   return variable;
  // };

  async function refurbishEndpointState(idEndpoint, variableData) {
    try {
      const updateEndpoints = [...dataEndpoints];
      const findIndexOfEndpoint = updateEndpoints.findIndex((items) => (
        items.endpoint._id === idEndpoint
      ));
      if (findIndexOfEndpoint !== -1) {
        try {
          const endpoint = endpoints.find((endpoint) => endpoint._id === idEndpoint);
          let responseData = [];
          const resFromVariable = findVariableExists(variableData);
          if (endpoint.typeFunction === 'one') {
            responseData = await oneSimulatorDatabase({
              idEndpoint,
              token,
              _id: resFromVariable?.state,
            });
            updateEndpoints[findIndexOfEndpoint] = {
              ...updateEndpoints[findIndexOfEndpoint],
              data: responseData,
            };
            setDataEndpoints(updateEndpoints);
          } else if (endpoint.typeFunction === 'customOne') {
            const paramInfo = updateEndpoints[findIndexOfEndpoint].paramData;
            const findVarParam = paramInfo.find((index) => (index.param === variableData));
            const {bodyData} = updateEndpoints[findIndexOfEndpoint];
            bodyData[findVarParam.paramendpoint.value] = resFromVariable?.state;
            const dataRetrieve = await oneSimulatorCustomDatabase({
              idEndpoint,
              token,
              body: bodyData,
            });
            updateEndpoints[findIndexOfEndpoint] = {
              ...updateEndpoints[findIndexOfEndpoint],
              data: dataRetrieve,
              bodyData,
            };
            setDataEndpoints(updateEndpoints);
          } else if (endpoint.typeFunction === 'customList') {
            const paramInfo = updateEndpoints[findIndexOfEndpoint].paramData;
            const findVarParam = paramInfo.find((index) => (index.param === variableData));
            const {bodyData} = updateEndpoints[findIndexOfEndpoint];
            bodyData[findVarParam.paramendpoint.value] = resFromVariable?.state;
            const dataRetrieve = await listSimulatorCustomDatabase({
              idEndpoint,
              token,
              body: bodyData,
            });
            updateEndpoints[findIndexOfEndpoint] = {
              ...updateEndpoints[findIndexOfEndpoint],
              data: dataRetrieve,
              bodyData,
            };
            setDataEndpoints(updateEndpoints);
          }
        } catch (error) {
          updateEndpoints[findIndexOfEndpoint] = {
            ...updateEndpoints[findIndexOfEndpoint],
            data: [],
          };
          setDataEndpoints(updateEndpoints);
        }
      }
    } catch (error) {
      //
    }
  }
  useEffect(() => {
    try {
      for (const searchState of localState) {
        const isRelatedToEndpoint = variablePerEndpoint.filter((index) => (index.variable._id === searchState._id));
        if (isRelatedToEndpoint.length > 0 && searchState.state) {
          for (const refreshPossibleEndpoint of isRelatedToEndpoint) {
            const verifyEndpointInList = dataEndpoints.find((endpoint) => endpoint.id === refreshPossibleEndpoint.endpoint._id);
            const paramInfo = verifyEndpointInList.paramData;
            const findVarParam = paramInfo.find((index) => (index.param === searchState._id));
            const {bodyData} = verifyEndpointInList;
            const originComparison = bodyData[findVarParam.paramendpoint.value];
            // bodyData[findVarParam.paramendpoint.value] = resFromVariable?.state;
            if (!originComparison) {
              refurbishEndpointState(verifyEndpointInList.id, searchState._id);
            } else if (originComparison !== searchState?.state) {
              refurbishEndpointState(verifyEndpointInList.id, searchState._id);
            }
          }
        }
      }
    } catch (error) {
      //
    }
  }, [localState]);

  useEffect(() => {
    try {
      for (const searchState of globalVariables) {
        const isRelatedToEndpoint = variablePerEndpoint.filter((index) => (index.variable._id === searchState._id));
        if (isRelatedToEndpoint.length > 0 && searchState.state) {
          for (const refreshPossibleEndpoint of isRelatedToEndpoint) {
            const verifyEndpointInList = dataEndpoints.find((endpoint) => endpoint.id === refreshPossibleEndpoint.endpoint._id);
            const paramInfo = verifyEndpointInList.paramData;
            const findVarParam = paramInfo.find((index) => (index.param === searchState._id));
            const {bodyData} = verifyEndpointInList;
            const originComparison = bodyData[findVarParam.paramendpoint.value];
            // bodyData[findVarParam.paramendpoint.value] = resFromVariable?.state;
            if (!originComparison) {
              refurbishEndpointState(verifyEndpointInList.id, searchState._id);
            } else if (originComparison !== searchState?.state) {
              refurbishEndpointState(verifyEndpointInList.id, searchState._id);
            }
          }
        }
      }
    } catch (error) {
      //
    }
  }, [globalVariables]);

  useEffect(() => {
    // const localSearcher = localStorage.getItem(`${namePage.slug}_simulator`);
    const filterData = dataGenerate.statePage.filter((index) => (index.idPage === page._id));
    setLocalState(filterData);
    // if (!localSearcher || localSearcher === false) {
    //   setFirstTimeSim(true);
    //   setMessageInfo(`Esta es una simulación del ${namePage.name} donde podrás realizar
    //   pruebas de su funcionamiento y visualización. Por favor no ingresar datos reales o sensibles.`);
    // }
    AOS.init();
    const handleData = async () => {
      if (page.authProtocol === 'active' && !token) {
        const erro404page = dataGenerate.pages.find((item) => item.url === '*');
        if (erro404page) {
          setLocalPage(erro404page);
        } else {
          setNotLogged(true);
        }
      }
      if (token) {
        try {
          const resLogged = await getLoggedSimulatorUser({
            token: 'asdsadsa',
            idProject: dataGenerate.project._id,
            tokenLoftyApps: token,
          });
          // console.log(resLogged);
          setUserInfo(resLogged.user);
          addUserApp(resLogged.user);
        } catch (error) {
          deletetokenApp();
          if (page.authProtocol === 'active' && !token) {
            const erro404page = dataGenerate.pages.find((item) => item.url === '*');
            if (erro404page) {
              setLocalPage(erro404page);
            } else {
              setNotLogged(true);
            }
          }
        }
      }
      if (page._id === localPage._id && !notLogged) {
        const readDatabase = searchComponentsBySlug(page.gjsComponents, 'ReadDBContainer');
        const threeCarrusel = searchComponentsBySlug(page.gjsComponents, 'threeSwiperComponent');
        const listEndpoint = [];
        const listVariableForEndpoint = [];
        // console.log(match);
        // console.log(readDatabase);
        for (const item of readDatabase) {
          const endpoint = endpoints.find((endpoint) => endpoint._id === item.endpointSelect);
          const exists = listEndpoint.find((endpoint) => endpoint.id === item.endpointSelect);
          // console.log(endpoint, exists);
          if (!exists && endpoint) {
            // console.log('help', listEndpoint, item);
            try {
              if (endpoint.typeFunction === 'list') {
                const data = await listSimulatorDatabase({idEndpoint: item.endpointSelect, token});
                listEndpoint.push({
                  id: item.endpointSelect,
                  data,
                  endpoint,
                });
              } else if (endpoint.typeFunction === 'one') {
                if (item.typeOfFilterManager === 'readcomponent' && item?.filterAdd) {
                  for (const getEndpointSelected of listEndpoint) {
                    const getEndpointData = getEndpointSelected?.data;
                    if (getEndpointData?.data.length > 0) {
                      for (const simData of getEndpointData.data) {
                        if (simData[item?.filterAdd]) {
                          const data = await oneSimulatorDatabase({
                            idEndpoint: item.endpointSelect,
                            token,
                            _id: simData[item.filterAdd],
                          });
                          const existingData = listEndpoint.find(
                            (valEndpoint) => (valEndpoint.dataId === data.data._id),
                          );
                          if (!existingData) {
                            listEndpoint.push({
                              id: item.endpointSelect,
                              dataId: data.data._id,
                              data,
                              endpoint,
                            });
                          }
                        }
                      }
                    } else if (getEndpointData?.data[item?.filterAdd]) {
                      const data = await oneSimulatorDatabase({
                        idEndpoint: item.endpointSelect,
                        token,
                        _id: getEndpointData.data[item.filterAdd],
                      });
                      const existingData = listEndpoint.find(
                        (valEndpoint) => (valEndpoint.dataId === data.data._id),
                      );
                      if (!existingData) {
                        listEndpoint.push({
                          id: item.endpointSelect,
                          dataId: data.data._id,
                          data,
                          endpoint,
                        });
                      }
                    }
                  }
                }
                if (item.typeOfFilterManager === 'variables' && item?.filterAdd) {
                  const findVariable = findVariableExists(item.filterAdd, filterData);
                  if (findVariable?.state) {
                    const data = await oneSimulatorDatabase({
                      idEndpoint: item.endpointSelect,
                      token,
                      _id: findVariable?.state,
                    });
                    listEndpoint.push({
                      id: item.endpointSelect,
                      data,
                      endpoint,
                    });
                  } else {
                    listEndpoint.push({
                      id: item.endpointSelect,
                      data: [],
                      endpoint,
                    });
                  }
                  listVariableForEndpoint.push({
                    variable: findVariable,
                    endpoint,
                  });
                }
                if ((item.typeOfFilterManager === 'params' || !item.typeOfFilterManager) && item?.filterAdd) {
                  const data = await oneSimulatorDatabase({
                    idEndpoint: item.endpointSelect,
                    token,
                    _id: match?.params[item.filterAdd],
                  });
                  listEndpoint.push({
                    id: item.endpointSelect,
                    data,
                    endpoint,
                  });
                }
              } else if (endpoint.typeFunction === 'customOne') {
                const arrayOfParams = item.attributes?.customParams;
                const findAllDBParams = arrayOfParams.filter((index) => (index.state === 'dbfield'));
                const findAllPageParams = arrayOfParams.filter((index) => (index.state === 'useparams'));
                const findAllVariableParams = arrayOfParams.filter((index) => (index.state === 'variable'));
                const arrangedBody = {};
                if (findAllVariableParams?.length > 0) {
                  findAllVariableParams.forEach((index) => {
                    const findVariable = findVariableExists(index.param, filterData);
                    arrangedBody[index.paramendpoint.value] = findVariable.state;
                    listVariableForEndpoint.push({
                      variable: findVariable,
                      endpoint,
                    });
                  });
                }
                if (findAllPageParams?.length > 0) {
                  findAllPageParams.forEach((index) => {
                    arrangedBody[index.paramendpoint.value] = match?.params[index.param];
                  });
                }
                // BEFORE QUERY SEARCH
                /**
                 * ================================
                 * ---- DB IN PARAMS EXISTS -------
                 * ================================
                 * If there is a db field in the params,
                 * search in every Read Endpoint before.
                 *
                 * @event *if(simData[paramFromComponent])
                 * In this case, there is a relation in the
                 * Read Endpoint and component
                 *
                 * @event else
                 * In this case, no relation exists.
                 * Therefore, the data has no origin from
                 * other Read DB.
                 */

                /**
                 * ======================================
                 * --- THERE IS NO DB FIELD IN PARAMS ---
                 * ======================================
                 *
                 * Simply uses query for custom normally
                 * All query will be unique instead of
                 * depending from an origin
                 */
                if (findAllDBParams?.length > 0) {
                  for (const getEndpointSelected of listEndpoint) {
                    const getEndpointData = getEndpointSelected?.data;
                    let tempArrayBody = arrangedBody;
                    if (getEndpointData?.data.length > 0) {
                      for (const simData of getEndpointData.data) {
                        let flagIfUsedDB = false;
                        findAllDBParams.forEach((index) => {
                          if (simData[index.param]) {
                            tempArrayBody[index.paramendpoint.value] = simData[index.param];
                            flagIfUsedDB = true;
                          }
                        });
                        const data = await oneSimulatorCustomDatabase({
                          idEndpoint: item.endpointSelect,
                          token,
                          body: tempArrayBody,
                        });
                        const hasOrigin = flagIfUsedDB ? simData._id : '';
                        const existingData = listEndpoint.find(
                          (valEndpoint) => (valEndpoint.fromOriginId === hasOrigin),
                        );
                        if (!existingData) {
                          const resultData = {
                            id: item.endpointSelect,
                            fromOriginId: hasOrigin,
                            data,
                            endpoint,
                            bodyData: tempArrayBody,
                            paramData: arrayOfParams,
                          };
                          listEndpoint.push(resultData);
                        }
                        tempArrayBody = {};
                      }
                    } else {
                      let flagIfUsedDB = false;
                      findAllDBParams.forEach((index) => {
                        if (getEndpointData?.data[index.param]) {
                          tempArrayBody[index.paramendpoint.value] = getEndpointData.data[index.param];
                          flagIfUsedDB = true;
                        }
                      });
                      const data = await oneSimulatorCustomDatabase({
                        idEndpoint: item.endpointSelect,
                        token,
                        body: tempArrayBody,
                      });
                      const hasOrigin = flagIfUsedDB ? getEndpointData.data._id : '';
                      const existingData = listEndpoint.find(
                        (valEndpoint) => (valEndpoint.fromOriginId === hasOrigin),
                      );
                      if (!existingData) {
                        const resultData = {
                          id: item.endpointSelect,
                          fromOriginId: hasOrigin,
                          data,
                          endpoint,
                          bodyData: tempArrayBody,
                          paramData: arrayOfParams,
                        };
                        listEndpoint.push(resultData);
                        tempArrayBody = {};
                      }
                    }
                  }
                } else {
                  const data = await oneSimulatorCustomDatabase({
                    idEndpoint: item.endpointSelect,
                    token,
                    body: arrangedBody,
                  });
                  listEndpoint.push({
                    id: item.endpointSelect,
                    data,
                    endpoint,
                    bodyData: arrangedBody,
                    paramData: arrayOfParams,
                  });
                }
              } else if (endpoint.typeFunction === 'customList') {
                const arrayOfParams = item.attributes?.customParams;
                const findAllDBParams = arrayOfParams?.filter((index) => (index.state === 'dbfield'));
                const findAllPageParams = arrayOfParams?.filter((index) => (index.state === 'useparams'));
                const findAllVariableParams = arrayOfParams?.filter((index) => (index.state === 'variable'));
                const arrangedBody = {};
                if (findAllVariableParams?.length > 0) {
                  findAllVariableParams.forEach((index) => {
                    const findVariable = findVariableExists(index.param, filterData);
                    arrangedBody[index.paramendpoint.value] = findVariable.state;
                    listVariableForEndpoint.push({
                      variable: findVariable,
                      endpoint,
                    });
                  });
                }
                if (findAllPageParams?.length > 0) {
                  findAllPageParams.forEach((index) => {
                    arrangedBody[index.paramendpoint.value] = match?.params[index.param];
                  });
                }
                // BEFORE QUERY SEARCH
                /**
                 * ================================
                 * ---- DB IN PARAMS EXISTS -------
                 * ================================
                 * If there is a db field in the params,
                 * search in every Read Endpoint before.
                 *
                 * @event *if(simData[paramFromComponent])
                 * In this case, there is a relation in the
                 * Read Endpoint and component
                 *
                 * @event else
                 * In this case, no relation exists.
                 * Therefore, the data has no origin from
                 * other Read DB.
                 */

                /**
                 * ======================================
                 * --- THERE IS NO DB FIELD IN PARAMS ---
                 * ======================================
                 *
                 * Simply uses query for custom normally
                 * All query will be unique instead of
                 * depending from an origin
                 */
                if (findAllDBParams?.length > 0) {
                  for (const getEndpointSelected of listEndpoint) {
                    const getEndpointData = getEndpointSelected?.data;
                    let tempArrayBody = arrangedBody;
                    if (getEndpointData?.data.length > 0) {
                      for (const simData of getEndpointData.data) {
                        let flagIfUsedDB = false;
                        findAllDBParams.forEach((index) => {
                          if (simData[index.param]) {
                            tempArrayBody[index.paramendpoint.value] = simData[index.param];
                            flagIfUsedDB = true;
                          }
                        });
                        const data = await listSimulatorCustomDatabase({
                          idEndpoint: item.endpointSelect,
                          token,
                          body: tempArrayBody,
                        });
                        const hasOrigin = flagIfUsedDB ? simData._id : '';
                        const existingData = listEndpoint.find(
                          (valEndpoint) => (valEndpoint.fromOriginListId === hasOrigin),
                        );
                        if (!existingData) {
                          const resultData = {
                            id: item.endpointSelect,
                            fromOriginListId: hasOrigin,
                            data,
                            endpoint,
                            bodyData: tempArrayBody,
                            paramData: arrayOfParams,
                          };
                          listEndpoint.push(resultData);
                        }
                        tempArrayBody = {};
                      }
                    } else {
                      let flagIfUsedDB = false;
                      findAllDBParams.forEach((index) => {
                        if (getEndpointData?.data[index.param]) {
                          tempArrayBody[index.paramendpoint.value] = getEndpointData.data[index.param];
                          flagIfUsedDB = true;
                        }
                      });
                      const data = await listSimulatorCustomDatabase({
                        idEndpoint: item.endpointSelect,
                        token,
                        body: tempArrayBody,
                      });
                      const hasOrigin = flagIfUsedDB ? getEndpointData.data._id : '';
                      const existingData = listEndpoint.find(
                        (valEndpoint) => (valEndpoint.fromOriginListId === hasOrigin),
                      );
                      if (!existingData) {
                        const resultData = {
                          id: item.endpointSelect,
                          fromOriginListId: hasOrigin,
                          data,
                          endpoint,
                          bodyData: tempArrayBody,
                          paramData: arrayOfParams,
                        };
                        listEndpoint.push(resultData);
                      }
                      tempArrayBody = {};
                    }
                  }
                } else {
                  const dataInfo = await listSimulatorCustomDatabase({
                    idEndpoint: item.endpointSelect,
                    token,
                    body: arrangedBody,
                  });
                  listEndpoint.push({
                    id: item.endpointSelect,
                    data: dataInfo,
                    endpoint,
                    bodyData: arrangedBody,
                    paramData: arrayOfParams,
                  });
                }
              }
            } catch (error) {
              // error
            }
          }
        }
        for (const item of threeCarrusel) {
          const endpoint = endpoints.find((endpoint) => endpoint._id === item.endpointSelect);
          const exists = listEndpoint.find((endpoint) => endpoint.id === item.endpointSelect);
          if (!exists && endpoint) {
            try {
              if (endpoint.typeFunction === 'list') {
                const data = await listSimulatorDatabase({idEndpoint: item.endpointSelect, token});
                listEndpoint.push({
                  id: item.endpointSelect,
                  data,
                  endpoint,
                  typeEndpoint: 'carouselList',
                });
              } else if (endpoint.typeFunction === 'customList') {
                const getAllCarouselItems = item.components?.filter((index) => (
                  index.sluglofty === 'reactCarouselComponent'
                  || index.sluglofty === 'booleanDynamicComponent'
                ));
                let indexCounter = 0;
                const arrayOfParams = item.attributes?.paramCarouselData;
                const findStaticValues = arrayOfParams?.filter((index) => (index.state === 'static'));
                const findAllPageParams = arrayOfParams?.filter((index) => (index.state === 'useparams'));
                const findAllVariableParams = arrayOfParams?.filter((index) => (index.state === 'variable'));
                // eslint-disable-next-line no-unused-vars
                for (const varSeek of getAllCarouselItems) {
                  const filterForCarouselStaticValues = findStaticValues?.filter((index) => (index.keyIndex === indexCounter));
                  const filterForCarouselPageParams = findAllPageParams?.filter((index) => (index.keyIndex === indexCounter));
                  const filterForCarouselVariableParams = findAllVariableParams?.filter((index) => (index.keyIndex === indexCounter));
                  const arrangedBody = {};
                  if (filterForCarouselVariableParams?.length > 0) {
                    filterForCarouselVariableParams.forEach((index) => {
                      const findVariable = findVariableExists(index.param, filterData);
                      arrangedBody[index.paramInfo.paramendpoint.value] = findVariable.state;
                      listVariableForEndpoint.push({
                        variable: findVariable,
                        endpoint,
                      });
                    });
                  }
                  if (filterForCarouselPageParams?.length > 0) {
                    filterForCarouselPageParams.forEach((index) => {
                      arrangedBody[index.paramInfo.paramendpoint.value] = match?.params[index.param];
                    });
                  }

                  if (filterForCarouselStaticValues?.length > 0) {
                    filterForCarouselStaticValues.forEach((index) => {
                      arrangedBody[index.paramInfo.paramendpoint.value] = index.value;
                    });
                  }
                  const dataInfo = await listSimulatorCustomDatabase({
                    idEndpoint: item.endpointSelect,
                    token,
                    body: arrangedBody,
                  });
                  listEndpoint.push({
                    id: item.endpointSelect,
                    data: dataInfo,
                    endpoint,
                    bodyData: arrangedBody,
                    paramData: arrayOfParams,
                    typeEndpoint: 'carouselCustom',
                    carouselRespective: indexCounter,
                  });
                  indexCounter += 1;
                }
              }
            } catch (error) {
            // error
            }
          }
          // const endpoint2 = endpoints.find((endpoint) => endpoint._id === item.endpointSelect2);
          // const exists2 = listEndpoint.find((endpoint) => endpoint.id === item.endpointSelect2);
          // if (!exists2 && endpoint2) {
          //   try {
          //     if (endpoint.typeFunction === 'list') {
          //       const data = await listSimulatorDatabase({idEndpoint: item.endpointSelect2, token});
          //       listEndpoint.push({
          //         id: item.endpointSelect2,
          //         data,
          //         endpoint,
          //       });
          //     }
          //   } catch (error) {
          //   // error
          //   }
          // }
          // const endpoint3 = endpoints.find((endpoint) => endpoint._id === item.endpointSelect3);
          // const exists3 = listEndpoint.find((endpoint) => endpoint.id === item.endpointSelect3);
          // if (!exists3 && endpoint3) {
          //   try {
          //     if (endpoint.typeFunction === 'list') {
          //       const data = await listSimulatorDatabase({idEndpoint: item.endpointSelect3, token});
          //       listEndpoint.push({
          //         id: item.endpointSelect3,
          //         data,
          //         endpoint,
          //       });
          //     }
          //   } catch (error) {
          //     // console.log(error);
          //   }
          // }
        }
        setDataEndpoints(listEndpoint);
        setVariablePerEndpoint(listVariableForEndpoint);
        // GET LOCAL
        // console.log(token);
        // const resLocalState = await listStatePage({
        //   idPage: page._id, idProject: dataGenerate.project._id, token,
        // });
        // console.log(resLocalState);
        // setLocalVariableData(resLocalState.data);
        // const resGlobalVar = await getGlobalVariables({idProject, token});
        // // console.log(resGlobalVar);
        // setGlobalVariableData(resGlobalVar.data);
      }
    };
    handleData();
  }, []);
  if (notLogged) {
    return (
      <div>
        Usuario no logueado
      </div>
    );
  }

  // function handleCloseModalSim() {
  //   try {
  //     setFirstTimeSim(false);
  //     localStorage.setItem(`${namePage.slug}_simulator`, 'true');
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // }

  return (
    <div>
      {/* <ModalSimulator
        open={firstTimeSim}
        onClose={() => handleCloseModalSim()}
        simPage={namePage.name}
        textinfo={messageInfo}
      /> */}
      <div>
        <style>
          {styleManagerDesktop(page.gjsStyles)}
          {styleManagerTablet(page.gjsStyles)}
          {styleManagerMobile(page.gjsStyles)}
        </style>
        {/* <div dangerouslySetInnerHTML={{__html: page.gjsHtml}} /> */}
      </div>
      <div>
        {
          localPage.gjsComponents.map((component, index) => (
            <DynamicComponent
              dataEndpoints={dataEndpoints}
              key={index}
              component={component}
              idProject={page.idProject}
              params={match?.params}
              addUserApp={addUserApp}
              addTokenApp={addTokenApp}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              token={token}
              user={user}
              userInfo={userInfo || user}
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              refurbishEndpoint={
                (idEndpoint, variableValue) => refurbishEndpointState(idEndpoint, variableValue)
              }
              variableForEndpoint={variablePerEndpoint}
            />
          ))
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addUserApp: (user) => dispatch(addUser(user)),
  deletetokenApp: () => dispatch(deletetoken()),
  addTokenApp: (token) => dispatch(addToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageDynamic);
